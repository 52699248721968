import React from 'react'
import { AllAnimationsCombined } from '../../../components/Animation/Animation'
import { Footer } from '../../../components/Footer'
import { Menu } from '../../../components/Menu/Menu'
import SEO from '../../../components/Seo/Seo'
import '../../../styles/style.scss'
import { TopSection } from './TopSection'

export const Trial = (props) => {
  const { datoCmsLanding } = props.data

  return (
    <>
      <SEO title="Skontaktuj się z nami" />
      <AllAnimationsCombined />
      <Menu data={props.data} location={props.location} />
      <main>
        <TopSection data={datoCmsLanding} />
      </main>
      <Footer />
    </>
  )
}