import React from 'react'
import '../../../styles/style.scss'
import { Contact } from '../Contact'


export const TopSection = ({ data }) => {
  const { subtitle, headline } = data

  return (
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">

          <Contact data={data} withoutHeader />

        </div>
      </div>
    </section>
  )

}
