/* global fbq */
import { navigate } from 'gatsby'
import { useI18next } from 'gatsby-plugin-react-i18next'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { AllAnimationsCombined } from '../../components/Animation/Animation'

const FORM_URL = "https://logs.getunderline.io/tracklog"

export const InputStyled = styled.input`
  font-size: 13px!important;
  border: 1.5px solid #F2F5FC!important;
  &:focus {
    border: 1.5px solid #6297FF!important;
    border-radius: 8px!important;
  }
  &:disabled {
    color: #98A6C7;
    background-color: #F2F5FC;
  }
  ::placeholder {
    color: #566280;
  }
  
  ${props => props.borderWhenFilled && css`
    border: 1.5px solid #6297FF!important;
    border-radius: 8px!important;
`}

`

export const Error = (props) => {
  const { text, withBorder } = props
  if (withBorder) {
    if (text) {
      return (
        <div style={{ marginBottom: 32, background: '#FFE9EB', borderRadius: 8, color: '#FF4F64', padding: 14, fontSize: 13, fontWeight: 500 }}>
          {text}
        </div>
      )
    }
  }

  if (!text) {
    return null
  }
  return (
    <p className="has-text-left form-error">{text}</p>
  )
}

export const SingleField = (props) => {
  const { inputProps, isErrorVisible, error, fieldError, fieldHint, style } = props
  const i18nHook = useI18next()

  return (
    <div className="field" style={{ marginBottom: 24, ...style }}>
      <label className="label has-text-left " style={{ fontSize: "11px", lineHeight: '170%' }}>{inputProps.label}</label>
      <div className="control" style={{ color: '#1E222D' }}>
        {inputProps.inputType === "input" &&
          <>
            <InputStyled
              borderWhenFilled={props.borderWhenFilled && inputProps.value.length > 0}
              onBlur={inputProps.onBlur}
              disabled={inputProps.disabled}
              required={inputProps.required}
              className="input"
              type="text"
              placeholder={inputProps.placeholder}
              name={inputProps.name}
              onChange={inputProps.handleChangeField}
              value={inputProps.value}
            />

            {fieldError && <span style={{ color: '#FF4F64', fontSize: 11, fontWeight: 500 }}>{fieldError}</span>}
          </>
        }
        {inputProps.inputType === "password" &&
          <>
            <InputStyled
              onBlur={inputProps.onBlur}
              required={inputProps.required}
              className="input"
              type="password"
              placeholder={inputProps.placeholder}
              name={inputProps.name}
              onChange={inputProps.handleChangeField}
              value={inputProps.value}
            />
            {fieldHint && !fieldError && <span style={{ color: '#566280', fontSize: 11, fontWeight: 500 }}>{fieldHint}</span>}
            {fieldError && <span style={{ color: '#FF4F64', fontSize: 11, fontWeight: 500 }}>{fieldError}</span>}
          </>
        }

        {inputProps.inputType === "textarea" &&
          <textarea
            className="textarea"
            placeholder={inputProps.placeholder}
            style={{ padding: "15px 16px" }}
            name={inputProps.name}
            onChange={e => inputProps.handleChangeField(e.target.value)}
            value={inputProps.value}
          />
        }
        {isErrorVisible && <Error text={error} />}
      </div>
    </div>
  )
}

export const Contact = (props) => {
  const i18nHook = useI18next()
  const { dowiedzSieWiecejHeader,
    dowiedzSieWiecejSubtitle,
    dowiedzSieWiecejCopy,
    dowiedziSieWiecejCta } = props.data
  const contactFormFields = props.data.contactForm

  const [isLoading, setIsLoading] = useState(false)
  const [isErrorVisible, setIsErrorVisible] = useState(false)
  const [formData, setFormData] = useState({
    data: "[EMAIL WYSLANY AUTOMATYCZNIE] Nowe zapisanie się do beta z landingu"
  })
  
  const handleChangeField = (fieldName) => (fieldValue) => {
    setFormData({
      ...formData,
      user_mail: fieldValue.target.value,
      send_underline_access: true,
      [fieldName]: fieldValue.target.value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!formData.email) {
      setIsErrorVisible(true)
    } else {
      setIsErrorVisible(false)
      setIsLoading(true)
      fetch(FORM_URL, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-type": "application/json",
          "Accept": "application/json"
        }
      }).then(res => {
        if (res.status === 200 | res.status === 201) {
          setFormData({
            email: ''
          })
          setIsLoading(false)
          i18nHook.navigate("/thank-you")
          if (typeof fbq !== 'undefined') {
            fbq('track', 'Lead');
          }
        }
      })
    }
  }
  return (
    <section className="hero is-halfheight" style={{ position: 'relative', }}>
      <AllAnimationsCombined contact />
      <div className="hero-body">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column has-text-centered">
              {!props.withoutHeader && (
                <h3 className="subtitle has-text-weight-semibold is-size-4" style={{ lineHeight: '170%' }}>
                  {dowiedzSieWiecejHeader}
                </h3>
              )}

              <h1 className="title is-2 has-text-weight-bold" style={{ lineHeight: '150%', }}>
                {dowiedzSieWiecejSubtitle}
              </h1>
              <div className="is-flex is-justify-content-center	">
                <p style={{ maxWidth: 568, lineHeight: '160%' }}
                  dangerouslySetInnerHTML={{ __html: dowiedzSieWiecejCopy }} />
              </div>
            </div>
          </div>

          <div className="columns is-vcentered" style={{ marginTop: 8 }}>
            <div className="column is-half is-offset-one-quarter has-text-centered">
              <form onSubmit={handleSubmit}>
                {contactFormFields.map(item => {
                  return (
                    <SingleField
                      key={item.name}
                      isErrorVisible={isErrorVisible}
                      error="Wpisz swój adres email"
                      inputProps={{
                        name: item.name,
                        label: item.label,
                        placeholder: item.placeholder,
                        inputType: item.inputType,
                        handleChangeField: handleChangeField(item.name),
                        value: formData[item.name]
                      }} />
                  )
                })}
                <div className="mt-5">
                  <button type="submit" className={`button is-primary has-text-weight-semibold is-3 ${isLoading && "is-loading"}`}>
                    {dowiedziSieWiecejCta}
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}